import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import thisFile from "../readmes/ReadmeWorkerdocker.js"

// **** CUSTOM IMPORTS
import GetStarted from '../nav/GetStarted'
import ShowFrameworkReadme from '../nav/ShowFrameworkReadme'
import PageNavUpDown from '../nav/PageNavUpDown'


import FullScreenImg from '../nav/FullScreenImg';
const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};



const DocumentationWorkerdocker= (props) => {



  console.log ("DocumentationWorkerdocker RENDERED______")
  
  const [showReadme, setShowReadme] = useState(false);
  
  const launchDfinity = () => {
    
    window.open ("https://dfinity.org/", "_blank" );
  };
  const showReadmeClick = () => {
    setShowReadme (!showReadme);
    console.log ("clicked to display readme");
  };




  var displayReadme =[
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={8} lg={4} >
          <ShowFrameworkReadme  showReadmeClick={showReadmeClick}/>
          </Grid>
          <Grid item xs={0} md={0} lg={4} >
            
          </Grid>
          <Grid item xs={12} md={4} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick}/>
          </Grid>
        </Grid>
        
      </Box>


  ];
  if (showReadme) {
    displayReadme = [
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={8} lg={8} >
                    
                
            <Typography
              component="h4"
              variant="h4"
              align="left"
              color="#000000"
              sx={{fontWeight:"bold", pt:2}}
            >
              Worker Module: ReadMe
            </Typography>
        
            <Link
                color="text.primary"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { showReadmeClick()}}
              >
                (hide)
              </Link>
          </Grid>
          <Grid item xs={12} md={4} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick}/>
          </Grid>
        </Grid>
        
        <ReactMarkdown remarkPlugins={[gfm]} >{thisFile}</ReactMarkdown>
      </Box>
    ];
  }

  //console.log ("thisFile: ",thisFile)
  
  console.log ("DocumentationWorkerdocker RENDERED______")



  
  const launchGitHub = () => {
    window.open ("https://github.com/icpipeline-framework", "_blank" );
  };
  

  return (
    <Paper elevation={0} sx={{ backgroundColor:"#ffffff", justifyContent:"right", borderRadius:2, p:2} }  style={styles.paperContainer}>
    <PageNavUpDown theWhereTo="Documentation" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>
    <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m:2,  p:0 }}>
  

      <Grid container>              
      
      <Grid item xs={12} sx={{pt:2}}>
        <Typography
          component="h5"
          variant="h5"
          align="center"
          color="#000000"
        >
          ICPipeline Worker Module (Docker)
        </Typography>
            <Typography variant="subtitle2" align="center"  sx={{ fontStyle:"italic"}} color="text.secondary">
            The Worker Module contains the Dockerfile and setup code, which the installer (or you, separately) 
            builds as your ICPipeline Worker container image.  
            Each individual container then clones and self-installs the Uplink Module (above) at birth.  
            Every Worker can deploy your IC projects either locally (i.e. to itself) or to the Internet Computer.

            </Typography>

      </Grid>
        
        <Grid item xs={12} >

        <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:0, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
        <FullScreenImg  theImgSrc="icpipeline-workerdocker-overview.png" theAltText="Worker Module (docker) - Workers are external to the ICPM and hold the necessary software to execute the Jobs assigned."/>
          
  
        </Box>

        </Grid>
      </Grid>
    </Box>
    <Grid container>
      <Grid item xs={12} >
      <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f",
          borderRadius:2,m:2, p:2, flexGrow:1, overflowX:"auto" }} >
      
      {displayReadme}
      </Paper>

      </Grid>
      </Grid>
    
    </Paper>


  );
} // end DocumentationWorkerdocker

export default DocumentationWorkerdocker;