import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// dr adding mui tooling for lists
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// dr import mui link tooling
import Link from '@mui/material/Link';

import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkWhatItIsnt = (props) => {

  console.log ("FrameworkWhat RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#003D7A", pt:2, pb:2, border:"1px solid #6b9cce", }} style={styles.paperContainer}>
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
      <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            },
            mt:1,
            fontWeight:"bold",
            lineHeight:.7
          }}
        >
          How much do we worry     
          </Typography>
         <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            },
            fontWeight:"bold",
            lineHeight:.7
          }}
        >
          about your project?
          </Typography>
         <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            },
            fontWeight:"bold",
            mt:1
          }}
        >
             Very much, actually.
          </Typography>
        

        <Typography variant="h5" align="left" color="text.primary" component="p" sx={{pb:2, fontStyle: "bold", fontSize: "1.2em"}}>
          ICPipeline does a lot of exciting things, right now.
          Our framework delivers practical upgrades to the IC dev experience, that are otherwise difficult or impossible to do in this ecosystem.
          So we're talking real value, is the point.
        </Typography>
        <Typography variant="h5" align="left" color="text.primary" component="p" sx={{pb:2, fontStyle: "bold", fontSize: "1.2em"}}>
          <i>But</i> we are asking you to trust <i>your</i> projects to ICPipeline, and that's something we take real seriously.
          If we were to throw out grand statements that might even <i>potentially</i> give you the wrong impression, we'd just lay awake nights worrying about it.
          We are not up for that, so we're going with complete openness and candor.
        </Typography>
        <Typography variant="h5" align="left" color="text.primary" component="p" sx={{pb:2, fontStyle: "bold", fontSize: "1.2em"}}>
          Here's the brief lowdown  ...
        </Typography>

        <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1,
                backgroundImage: {
                  xs: ``,
                  sm: `url(${"pipe-bw.png"})`,
                } ,
                backgroundRepeat: `no-repeat`,
                backgroundPositionX:"0%",
      
                backgroundPositionY:"-2050px",
                }} >
                <Grid item xs={12} md={4}>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container>
                      <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                          <Box sx={{width:"40px"}} >  
                            <img
                            src={
                            "dinfinity.png"
                            }
                            srcSet={"dinfinity.png?"}
                            alt="Dfinity Logo"
                            loading="lazy"
                            style={{width:"100%", display:"block"}}
                            />
                          </Box>
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold", ml:1}}>
                          ICPipeline, here and now:
                        </Typography> 
                      </Grid>
                      <Grid item xs={12}>
                        
                  <Box sx={{backgroundColor:"#ffffff", border:" 1px solid #cccccc", borderRadius:2, mt:2}} >
                        
                      <List>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="All-on-chain admin console/dashboard for E2E pipeline management" />
                      
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="On-demand, pre-tooled IC replica environments" />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Multi-tiered bespoke project pipelines" />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Local Internet Identity and Ledger canisters at a click for you to integrate with" />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Fully networked, access-controlled walled garden environments collaborate and share." />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Seamless, fast and free onboarding experience?" />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Integrates CLI Toolkit for augmented workflows in conjunction with the GUI tools?" />
                        
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Snapshots and archiving tools for moment-in-time backups and saves -- of your code and canister state -- with point-and-shoot restore?" />
                        
                      </ListItem>
                      <ListItem >
                        <ListItemIcon>
                          <CheckCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Magical Deploy button that captures even complex, proprietary, multi-canister build sequences?" />
                      
                      </ListItem>
                      </List>
                  </Box>
                      </Grid>
                      </Grid>
              </Grid>
            </Grid>


  
        <Typography variant="h5" align="left" color="text.primary" component="p" sx={{p:3, m:3, border: "1px solid #cccccc", borderRadius:2, fontStyle: "italic"}}>
          ICPipeline does <i>many</i> things, and we are very confident about our value proposition in the present tense. So, know that ICPipeline <i>can</i> be the backbone of your working project pipeline.
          And that's right now, today. And most or all (depending on how you want it) of your pre-prod lifecycle happens in access-controlled walled gardens, burning zero cycles, that you can stand up in minutes.
        </Typography>
{/* 
        <Typography variant="h5" align="center" color="text.primary" component="p" sx={{pt:0, pb:2,fontStyle:"bold"}}>
          But, CI/CD is a big term that covers <Box fontStyle="italic" display="inline">a lot</Box> of real estate.
        </Typography> */}

        <Typography variant="subtitle2" align="left" color="text.primary" component="p" sx={{pb:2 }}>
          We feel compelled to shoot you straight, especially as relates to the term CI/CD.
          We know what it really means, and we know the awesome slickness (and the hands-on utility) of the best Web2 tools, circa 2023.
          We feel those baseline expectations acutely, and it makes us cautious about how we throw the term around.
        </Typography>

        <Typography variant="subtitle2" align="left" color="text.primary" component="p" sx={{pb:2}}>
          Is it full-blown <i>Jenkins</i>, <i>TeamCity</i> or <i>CircleCI</i> ? Or it as robust as <i >Kubernetes</i>, <i>Travis</i> and <i>GitLab</i> ?
          Not just yet. We may also find that integrating into those platforms to do the Internet Computer specific build components might be an option in many cases.
        </Typography>
        
        <Typography variant="subtitle2" align="left" color="text.primary" component="p" sx={{pb:2}}>
          We know those products, what they do, and how. ICPipeline is new and growing but, it is not everything we want it to be just yet.
          We know that you are looking for something that will help manage your project. That's what we're talking about, and we just will not mess around, as far as that goes.
          Take the journey with us, help us to help you and your team. We want you to be vocal and tell us what you need an what it's missing every step of the way. 
        </Typography>
      
      </Box>

      <PageNavUpDown theWhereTo="FrameworkHow" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  ); // end return block
} // end FrameworkWhatItIsnt

export default FrameworkWhatItIsnt;