import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import CircleIcon from '@mui/icons-material/Circle';

// dr adding mui tooling for lists
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// dr import mui link tooling
import Link from '@mui/material/Link';

// **** CUSTOM IMPORTS
import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkHow = (props) => {

  console.log ("FrameworkHow RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#003D7A", pt:2, pb:2, border:"1px solid #6b9cce", }} style={styles.paperContainer}>
     
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
  
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }, 
            fontWeight:"bold"
          }}
        >
          How Does it Work?
        </Typography>

        <Typography variant="h5" align="center" color="text.primary" component="p" sx={{pt:0, pb:2,fontStyle:"italic"}}>
          Build and deploy your IC canister d'apps -- simpler, faster and better with ICPipeline.
        </Typography>
        <br />
        <Typography variant="h7" align="left" color="text.primary" component="p">
          Immutable public ledgers, for all their virtues, don't make it very easy to create access-controlled, dev-friendly environments for the pre-production SDLC.
          It's safe to say that "immutable" and "public" are not words that most of us associate with the pre-prod lifecycle.
        </Typography>
        <br />
        <Typography variant="h7" align="left" color="text.primary" component="p">
          ICPipeline is the Swiss Army knife for this problem space, straddling the gap between off-chain deployment and the live blockchain.
        </Typography>
        <br />
        <Typography variant="h7" align="left" color="text.primary" component="p">
          This hybrid framework makes it easy for builders and teams to create bespoke pipelines that transition seamlessly, while adhering to the best practices of continuous integration and delivery.
        </Typography>
        <br />
        <Typography variant="h7" align="left" color="text.primary" component="p">
          The framework's hub is the ICPipeline Manager d'app, aka ICPM.
          ICPM is the console dashboard, running all-on-chain, with which users and teams are able to completely manage their projects.
          ICPipeline simplifies and accelerates most development tasks with user-centered UI and menus, but without wallpapering over crucial concepts.
          Your IC developer experience comes through intact.
          That has been a core requirement since before ICPipeline even had a name, when it was just an ad hoc kit of tools we built for our own quality of life.
        </Typography>
        <br />
        <Typography variant="h7" align="left" color="text.primary" component="p">
          ICPipeline is a composable framework, which is to say that all its components snap together like Lego bricks.
          So it does what you need, whatever you need.
          A typical trajectory goes something like this:
        </Typography>
        

        <Box elevation={0} sx={{ backgroundColor: "#ffffff",  m:"auto", mt:2, mb:0, width:"100%",  justifyContent:"center"}} >

        <FullScreenImg theImgSrc="ICPipelineExamplePipelineDiagram.png" theAltText="How Does it Work? - Create and Deploy dApps to multiple, secured, Internet Computer Environments."/>

          <List
          sx = {{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }
        }>
          <ListItem>
            You sign into ICPM at icpipeline.com using your Internet Identity, which auto-creates your ICPipeline user profile.
          </ListItem>
          <ListItem>
            Create your first "Replicator" by connecting your existing local dev environment directly to the framework.  It's just a pastable snippet that runs in about a minute.
          </ListItem>
          <ListItem>
            Add your project by entering one simple "icpipeline project add" in any terminal window.
          </ListItem>
          <ListItem>
            Go right back to coding, right where you left off, except your workflows are now powered up with ICPipeline tools.
          </ListItem>
          <ListItem>
            Then, when ready, fire up a Remote Host Replicator with just a few clicks.
          </ListItem>
          <ListItem>
            Now you have a fully networked replica environment, with shareable links to your deployments, etc.
          </ListItem>
          <ListItem>
            Basically rinse and repeat until your project is ready, then deploy it to on-chain canisters.
          </ListItem>

          
        </List>

            
        </Box>

      </Box>

      <PageNavUpDown theWhereTo="FrameworkWhy" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
} // end FrameworkHow

export default FrameworkHow;