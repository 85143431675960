import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// dr adding mui tooling for lists
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// dr import mui link tooling
import Link from '@mui/material/Link';

import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';
import { ArrowRight } from '@mui/icons-material';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const ProductsMain = (props) => {

  console.log ("ProductsMain RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#003D7A", pt:2, pb:2, border:"1px solid #6b9cce", }} style={styles.paperContainer}>
    
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>
        
      <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
        
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }, 
            fontWeight:"bold"
          }}
        >
          ICPipeline Products
        </Typography>

        

        <Typography variant="h5" align="center" color="text.primary" component="p" sx={{pt:0, pb:2,fontStyle:"regular"}}>
          Are buckets and shovels <i>composable</i>?  We think so.
        
          That's what we're building, for the Internet Computer.
        
        
        </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{mb:1.5}}>
          We believe the blockchain makes the world a better place.
          But let's face it, the immutable public ledger isn't a perfect fit for the pre-production SDLC.
        </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{mb:1.5}}>
          We can build and deploy on our laptops, or we can do it on the mainnet blockchain.
          There's a big space between those poles, and most of a best-practices SDLC lives there.
        </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{mb:1.5}}>
          Certain things are hard or impossible to achieve on-chain:
          privacy and access control; full-stack visibility; virtualization; networkability; breakability etc.
          ICPipeline products work together to deliver best-practices development to the Internet Computer ecosystem.
        </Typography>

          <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold"}}>
                  ICPM: ICPipeline Manager & Dashboard

                  </Typography> 
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                    <ListItem>
                      The console dashboard for the decentralized cloud.
                    </ListItem>
                    <ListItem>
                      A marketplace, distribution channel and monetization engine for solutions built across the whole community.
                    </ListItem>
                    <ListItem>
                      A DevOps command center for builders and teams at all levels.
                    </ListItem>
                    <ListItem>
                      The orchestration layer for real CI/CD on the Internet Computer.
                    </ListItem>
                    <ListItem>
                      A hub where the community can share, percolate and grow.
                    </ListItem>
                    <ListItem>
                      A menu-driven platform where builders can find the resources they need for what's next.
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center"}}>
                    <Box sx={{ border:"0px solid #cccccc", borderRadius:2, p:2, m:1, width:"350px"}}>
                    <FullScreenImg theImgSrc="screenlogin.png" theAltText="Replicator Login"/>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                <List
                  sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }
                }>
                  <ListItem>
                    Home for documentation and other learning materials.  The knowledge users need, when they need it.
                  </ListItem>
                </List>
            </Grid>
        </Grid>

        <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold"}}>
                  The ICPipeline Replicator Service

                  </Typography> 
                </Grid>
                <Grid item xs={12} md={4} sx={{display:"flex", justifyContent:"center"}}>
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:3,}}>
                      <FullScreenImg theImgSrc="screendeploy.png" theAltText="Replicator Deploy"/>
                    </Box>
                
                </Grid>
                
                <Grid item xs={12} md={8}>
                <List
                  sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }
                }>
                  <ListItem>
                    Start locally, and graduate to on-demand, pre-tooled IC replica environments, available with a few clicks.
                  </ListItem>
                  <ListItem>
                    Our Remote Host Replicators are built on Ubuntu Linux (they're robust dedicated hosts, and you get full access).
                  </ListItem>
                  <ListItem>
                    Run locally, as cloud VMs, or containerize, all with identical UI and workflows.
                  </ListItem>
                  <ListItem>
                    Different formats meet different requirements.
                  </ListItem>
                  <ListItem>
                    Already equipped with DFX, NodeJS/NPM and Git, along with ICPipeline's native tools.
                  </ListItem>
                  <ListItem>
                    Your <i>Internet Identity</i> and <i>ICP Ledger</i> backend is already there and working; so you can think about what you want to do <i>with</i> it.
                  </ListItem>
                </List>
                </Grid>
                <Grid item xs={12}>
                <List
                  sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }
                }>
                  <ListItem>
                    Onboard "snapshot" capability allows you to capture any moment in time, of any project, and roll it back at any time.
                  </ListItem>
                  <ListItem>
                    Keep right on coding in VSCode or your IDE of choice.
                  </ListItem>
                  <ListItem>
                    Tightly integrated and manageable via your ICPM login (this is automatic and included).
                  </ListItem>
                </List>
                </Grid>
                <Grid item xs={12} md={12} sx={{display:"flex", justifyContent:"center"}}>
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}}>

                    <FullScreenImg theImgSrc="screenreplicator.png" theAltText="Replicator Interfaces"/>
                    </Box>

                </Grid>
                <Grid item xs={12}>
                <List
                  sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }
                }>
                <ListItem>
                  Fully networked.  Deploy your branch, and ship the link to your team.  No more shoulder surfing and screen sharing.
                </ListItem>
                <ListItem>
                  Non-invasive, non-disruptive.  There's no "migration" to speak of, and NO changes are required to your code.
                </ListItem>
                <ListItem>
                  Deploy canisters, by the thousands if you need 'em, without burning cycles or hassling on crypto exchanges.
                </ListItem>
                </List>
            </Grid>
        </Grid>



        <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold"}}>
                  ICArchive
                  </Typography> 
                </Grid>
                
           <Grid item xs={12} md={6}>
              <List
                sx = {{
                  listStyleType: 'disc',
                  pl: 2,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }
              }>
                <ListItem>
                  Extract canister state data from any d'app.
                </ListItem>
                <ListItem>
                  Your archives live in your own private, dedicated archive canister.
                </ListItem>
                <ListItem>
                  Browse your archive library.
                </ListItem>
                <ListItem>
                  Easy "point and shoot" restore to any canister.
                </ListItem>
                </List>
                </Grid>
                <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center", borderLeft :{xs:"", md: "1px solid #cccccc"},}}>
                    <Box sx={{ border:"0px solid #cccccc", borderRadius:2, p:2, m:1,mb:2,  backgroundColor:"#ffffff"}}>

                    <Grid container onClick={() => { props.mainNavClick("ICArchive")}} sx={{cursor:"pointer", width:"230px",height: "65px", border:"0px solid #000"}}>
                    <Grid item xs={12} sx={{border:"0px solid #00ff00"}}>
                    <Typography align="center" color="#cc6600" sx={{fontWeight: 'bold',pt:1, pb:.5,pl:1, m:0, lineHeight:1, fontSize:"2.5em"}} component="h4" variant="h4">
                    ICArchive
                    <Typography align="center" color="#cc6600" sx={{fontSize:".2em"}}  variant="subtitle">TM</Typography>
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:"flex", justifyContent: "center"}}>
                    <Box sx={{pt:1,
                    backgroundImage: {
                      xs: ``,
                      sm: `url("logo-color-underline.png")`,
                    } ,
                    backgroundRepeat: `no-repeat`,
                    backgroundSize:"contain", width:"220px"}} >
                    </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ display:"flex", justifyContent:"center"}}>

                        <Typography variant="subtitle2" color="text.secondary"  sx={{ mt:1, border:"0px solid #000", m:"auto", fontWeight:"bold", fontStyle:"italic", }}>
                        visit the site to learn more:
                        </Typography> 
                                    
                    </Grid>
                    <Grid item xs={12} sx={{ display:"flex", justifyContent:"center",alignItems:"center"}}>
                          <Link
                            color="#cc6600"
                            href="#"
                            underline="hover"
                            sx={{ my: 1, mx: 1.5 }}
                            onClick={() => { props.mainNavClick("ICArchive")}}
                          >
                            icarchive.com
                          </Link>
                    </Grid>
                    </Grid>

                    </Box>

                </Grid>
                <Grid item xs={12}>
                <List
                  sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                      display: 'list-item',
                    },
                  }
                }>
              <ListItem>
                Archives export easily, for low-cost off-chain storage anywhere.
              </ListItem>
              </List>
          </Grid>
          <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center"}}>
              
              <Box sx={{mt:1}}>
              
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} /> Disaster recovery
              </Typography>
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} />  Multi-homing
              </Typography>
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} />  Business continuity
              </Typography>
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} />  Custody and provenance
              </Typography>
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} />  Compliance and regulatory
              </Typography>
              <Typography variant="h6" align="left" color="text.secondary" component="p" sx={{fontWeight:"", display:"flex", alignItems:"center"}}>
               <CheckCircleOutlineIcon sx={{mr:2}} />  Risk and insurability
              </Typography>
              
              </Box>
              
              
              
              
              
          </Grid>
          <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center"}}>
              <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}}>

              <FullScreenImg theImgSrc="icarchive-yourapp-what.png" theAltText="ICArchive"/>
              </Box>

          </Grid>
          <Grid item xs={12}>
          <List
            sx = {{
              listStyleType: 'disc',
              pl: 2,
              '& .MuiListItem-root': {
                display: 'list-item',
              },
            }
          }>
          <ListItem>
            The road to <i>>blockchain singularity</i> leads through big rooms where they ask hard questions about those things.  We've been there.
          </ListItem>
          <ListItem>
            ICArchive is the answer.
          </ListItem>
        </List>
          </Grid>
        </Grid>
          <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold"}}>
                  The ICPM CLI Toolkit

                  </Typography> 
                </Grid>
                <Grid item xs={12} md={6} sx={{display:"flex", justifyContent:"center"}}>
                    <Box sx={{ border:"0px solid #cccccc", borderRadius:2, p:2,pt:0, m:1, width:"350px"}}>
                    <FullScreenImg theImgSrc="screenclihelp.png" theAltText="ICPM CLI Toolkit"/>
                    </Box>

                </Grid>
                
                <Grid item xs={12} md={6}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                  <ListItem>
                    ICPipeline tooling at the command line -- compliments and extends the ICPM UI.
                  </ListItem>
                  <ListItem>
                    Tightly integrated with ICPM on the blockchain.
                  </ListItem>
                  <ListItem>
                    Intuitive semantic vocab that will grow and expand with the framework.
                  </ListItem>
                  <ListItem>
                    Just another way of talking to the API, like it oughta be.
                  </ListItem>
                  </List>
                </Grid>
        </Grid>
          <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold"}}>
                  The ICPipeline Framework

                  </Typography> 
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                  <ListItem>
                    Access-controlled network collaboration, sharing, testing and QA.
                  </ListItem>
                  <ListItem>
                    Consistent.  Iterate, break stuff, fail fast, rinse and repeat.
                  </ListItem>
                  <ListItem>
                    All levels -- from fleet-of-one to enterprise CI/CD.
                  </ListItem>
                  <ListItem>
                    Persistent, durable, dedicated tiered environments.
                  </ListItem>
                  <ListItem>
                    Be in full control, front to back.
                  </ListItem>
                  <ListItem>
                    Pre-prod environments that are as public, or as private, as they need to be.
                  </ListItem>
                  <ListItem>
                    Streamlined and simplified, but still comprehensive.
                  </ListItem>
                  <ListItem>
                    Delivering IC development, straight up -- just simpler, faster, better.  No code changes and no proprietary <i>Kool-Aid</i>.
                  </ListItem>
                </List>
        
                </Grid>
                <Grid item xs={12} md={6} sx={{ justifyContent:"center"}}>
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}}>
                    <FullScreenImg theImgSrc="productframwork.png" theAltText="Overall Framework"/>
                    </Box>

                </Grid>
        </Grid>


      </Box>

      <PageNavUpDown theWhereTo="AboutHelp" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );

}// end FrameForIndividuals

export default ProductsMain;