import React, { useState, useContext} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';
import { borderRadius } from '@mui/system';

// **** CUSTOM IMPORTS


import GetStarted from "../nav/GetStarted";
import LoginICPMNow from "../nav/LoginICPMNow";
import GitbookSpace from "./GitbookSpace";

import AppContext from '../nav/AppContext';


const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-bw.png"})` ,
      backgroundRepeat: `no-repeat`,
      backgroundPositionX:"30%",
      backgroundPositionY:"-2050px",
      height:"460px",
  }
};

const DocumentationGitBookWindow = (props) => {



  console.log ("MainTop RENDERED______")

  const myContext = useContext(AppContext);

  const whereTo = myContext.whereToName; 

  
  const launchDfinity = () => {
    window.open ("https://dfinity.org/", "_blank" );
  };

  const docsHeaderMouseOut = ()  => {
    //document.getElementById("startButtonId").style.backgroundColor="#4db25d";
    document.getElementById(`documentationheaderpaper`).style.backgroundColor="#ffffff";
    document.getElementById(`documentationheadertypography`).style.color="#000000";
    document.getElementById(`documentationheadertypography`).innerText="Documentation";
  }
  const docsHeaderMouseOver = ()  => {
    document.getElementById(`documentationheaderpaper`).style.backgroundColor="#dcedff";
    document.getElementById(`documentationheadertypography`).style.color="#000000";
    document.getElementById(`documentationheadertypography`).innerText="Documentation";
  }
  

  if (whereTo != "main") {
    myContext.setWhereTo("main");
    return (
      <Paper elevation={0} sx={{border:"0px solid #6b9cce", backgroundColor: "#fff",border:"1px solid #6b9cce", borderRadius:2, justifyContent:"right"}} >
        ...
  
      </Paper>
  
  
    );

  } else {
    
  return (
    <Paper elevation={0} sx={{border:"0px solid #6b9cce", backgroundColor: "#fff",border:"1px solid #6b9cce", borderRadius:2, justifyContent:"right"}} >
      <Grid container>
      <Grid item xs={12} sx={{ display:"flex"}}> 
      

            <Paper elevation={0} id={"documentationheaderpaper"} onMouseOver={docsHeaderMouseOver} onMouseOut={docsHeaderMouseOut} onClick={() => { myContext.setWhereTo("load")}} sx={{ cursor:"pointer", backgroundColor: "#ffffff", border:"1px solid #9f9f9f", borderRadius:2,m:2, p:2, flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="#000000"
                id={"documentationheadertypography"}
              >
                Documentation
              </Typography>

              </Grid>
            </Grid>
          </Paper>
        
        </Grid>
        <Grid item xs={12} sx={{}}>
          <Box sx={{m:3, p:1 , border:"1px solid #cccccc", borderRadius:2}} >  
              <GitbookSpace spaceId={"SnsXVBbldFlYhoR0CNBn"} spaceUrl={"https://icpipeline.com/prod/GitbookProxy?apiOrPage=page&thePath=/icpipeline-complete-documentation/"}   />
          </Box>
        </Grid>

      </Grid>

    </Paper>


  );
  } // if where to 
} // end DocumentationGitBookWindow

export default DocumentationGitBookWindow;