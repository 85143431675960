import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import thisFile from "../readmes/ReadmeComplete.js"

// **** CUSTOM IMPORTS
import GetStarted from '../nav/GetStarted'
import ShowFrameworkReadme from '../nav/ShowFrameworkReadme'

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';
const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};



const DocumentationReplicator=  (props) => {



  console.log ("DocumentationReplicator RENDERED______")
  
  const [showReadme, setShowReadme] = useState(false);
  

  const launchDfinity = () => {
    
    window.open ("https://dfinity.org/", "_blank" );
  };
  const showReadmeClick = () => {
    setShowReadme (!showReadme);
    console.log ("clicked to display readme");
  };



  var displayReadme =[
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={5} lg={4} sx={{display:"flex", flexDirection:"column "}} >

          <ShowFrameworkReadme showReadmeClick={showReadmeClick} />

          </Grid>
          <Grid item xs={0} md={2} lg={4} >
            
          </Grid>
          <Grid item xs={12} md={5} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick} />
          </Grid>
        </Grid>
        
      </Box>


  ];
  if (showReadme) {
    displayReadme = [
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={8} lg={8} >
                    
                
            <Typography
              component="h4"
              variant="h4"
              align="left"
              color="#000000"
              sx={{fontWeight:"bold", pt:2}}
            >
              Framework: ReadMe
            </Typography>
        
            <Link
                color="text.primary"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { showReadmeClick()}}
              >
                (hide)
              </Link>
          </Grid>
          <Grid item xs={12} md={4} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick}/> 
          </Grid>
        </Grid>
        
        <ReactMarkdown remarkPlugins={[gfm]} >{thisFile}</ReactMarkdown>
      </Box>
    ];
  }

  //console.log ("thisFile: ",thisFile)

  
  const launchGitHub = () => {
    window.open ("https://github.com/icpipeline-framework", "_blank" );
  };
  

  return (
    <Paper elevation={0} sx={{ backgroundColor:"#ffffff", justifyContent:"right", borderRadius:2, p:2} }  style={styles.paperContainer}>
      <PageNavUpDown theWhereTo="Documentation" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

    <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m:2,  p:0 }}>
  

      <Grid container>              
      
      <Grid item xs={12} sx={{pt:2}}>
        <Typography
          component="h5"
          variant="h5"
          align="center"
          color="#000000"
        >
          ICPipeline Replicator
        </Typography>
              <Typography variant="subtitle2" align="center"  color="text.secondary" sx={{ fontStyle:"italic", pr:2, pl:2, pt:2}} >
              
                The Replicator service provides virtually frictionless access to one ICPipeline’s core tools: robust, dedicated IC replica environments.
              </Typography>


          <Grid container>
            <Grid item xs={9} >

              <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >

                <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:2, pt:2}} >
                Spin up a Replicator (or several) in just a few clicks, and you’ll have a private, robust, dedicated Internet Computer replica environment, fully networked and network-available. They’re pre-tooled with DFX, Node/npm, Git and more.  Just check the box for pre-installed Internet Identity and IC Ledger backends for integration into your d’apps.
                </Typography>
                <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:2, pt:2}} >
                To use the Replicator service, just go to ICPipeline.com with your Internet Identity at the ready.

                </Typography>
                <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:2, pt:2}} >
                You’ll need an Internet Identity to use the Replicator service.  Your II authentication will auto-create your profile, which you can use to manage as many Replicators as you need.

                </Typography>

                


              </Box>

            </Grid>
            <Grid item xs={3} >
              

              <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
                <FullScreenImg  theImgSrc="screens/loginScreen_LG.png" theAltText="ICPipeline Replicator - Login with Internet Identity. "/>
            
        
              </Box>
            </Grid>

            <Grid item xs={6} >  

                <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
                  <FullScreenImg  theImgSrc="screens/stopped_state_LG.png" theAltText="ICPipeline Replicator - Stopped Recpliator. "/>


                </Box>
              
            </Grid>
            <Grid item xs={6} >

              <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
              <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:1, pt:2}} >
                Getting Started:  
              </Typography>
              <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:2, pt:0}} >
              
                <ol>
                  <li>
                    First, authenticate with II, biometrically or with your password.  Your first sign-on will auto-create a profile consisting of nothing more than your principal.  Fill out your profile, or not, entirely at your option.  ICPipeline is not in the business of harvesting data.
                  </li>
                  <li>
                    Click “Create New Replicator”
                  </li>
                  <li>
                    Enter a name for your Replicator however you like.  Names like “QA 01”, “Dev 02”, etc. are typical.  There’s an optional field for a description.
                  </li>
                </ol>
                  
                  
                  
                  
                  
                  
                </Typography>
              </Box>
              
            </Grid>

            <Grid item xs={6} >



            <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
              <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:1, pt:2}} >
                
                Configuring your Replicator:
              </Typography>
              <Typography variant="subtitle2" align="left"  color="text.secondary" sx={{ fontStyle:"", pr:2, pl:2, pt:0}} >
              
                <ol>
                  <li>
                    You’ll see checkboxes indicating whether to include local replica canisters (i.e. on your Replicator) for both Internet Identity and a replica Ledger.  Both options are checked by default; uncheck to omit either or both.  They don’t eat much.
                  </li>
                  <li>
                    Next, you’ll enter the GitHub URL for the IC project you’d like to install on your Replicator.  Don’t forget to include your Git branch, and there’s a field for your GitHub auth token if your repo is private*.  Alternatively, just click the button to deploy the supplied demo project (basically Dfinity’s Hello project) instead.  That works great if you’re just taking it for a spin.
                  </li>
                  <li>
                    Click Submit to create your new Replicator.
                  </li>
                </ol>
                  
                  
                  
                  
                  
                  
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} >

              <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:3, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
                <FullScreenImg  theImgSrc="screens/running_state_LG.png" theAltText="ICPipeline Replicator - Running Replicator. "/>


              </Box>
              
            </Grid>

          </Grid>

            
            

      </Grid>
        
      </Grid>
    </Box>
    
      <PageNavUpDown theWhereTo="DocumentationComplete" theUpDown="down" theUpDownText="next" mainNavClick={props.mainNavClick}/>
    </Paper>


  );
} // end DocumentationReplicator

export default DocumentationReplicator;