import React, { useState, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter,useNavigate,useLocation} from 'react-router-dom';

import { green } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

// **** CUSTOM IMPORTS

import Stage from './stage_components/Stage';

import AppContext from './nav/AppContext';
import FooterMain from './nav/FooterMain';


// import { initJuno } from "@junobuild/core";

// // TODO: Replace the following satelliteId with your app's effective satellite ID.
// await initJuno({
//   satelliteId: "izef7-piaaa-aaaal-ab63a-cai",
// });

const App = () => {



  console.log ("______APP RENDERED______")


  /*Auth*/
  const [isAuthed, setIsAuthed] = useState(false);
  const [whereTo, setWhereTo] = useState(false);


  // now we create the theme
  const defaultTheme = createTheme({
    palette: {
      primary: {
        light: "#004487",
        main: "#003D7A",
        dark: "#0063c6",
      },
      secondary: {
        light: "#004487",
        main: "#ffffff",
        dark: "#45a2ff",
      },
      background: {
        default: '#003D7A',
        paper: '#FFFFFF',
      },
    },
  });

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const copyToClip = async(whatToCopy,setStateVar,newStateVal)=> {
                                          
    await navigator.clipboard.writeText(whatToCopy);
    console.log ("whatToCopy: ", whatToCopy)
    setStateVar ("copied")
    await sleep (2000);
    console.log ("copied");
    setStateVar (newStateVal);
  }

  const userSettings = {
    /*Auth*/
    isAuthedName:isAuthed,
    whereToName:whereTo,
    /*Themes*/
    defaultThemeName: defaultTheme,
    /*AuthFunc*/
    setIsAuthed,
    setWhereTo,
    sleep,
    copyToClip
  }

  
  


  return (
    
    <AppContext.Provider value={userSettings}>
    <ThemeProvider theme={defaultTheme}>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <BrowserRouter initialEntries={['/framework']} initialIndex={0}>   
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
          <CssBaseline />
          <Stage />
  

            
        

      </BrowserRouter>
      </Box>
    </ThemeProvider>
    </AppContext.Provider>

  );
}

export default App;