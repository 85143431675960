import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS
import PageNavUpDown from '../nav/PageNavUpDown';



const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const AboutWho = (props) => {



  console.log ("AboutWho RENDERED______")



  const mattBlurb = `Matt started his digital career when the world wide web was born and has a real life understanding of 
                      how highly developed product defines the customer experience. He brings tremendous value to this project 
                      leveraging decades of business development, software architecture,  analytics and team building for 
                      both small and enterprise scale product platforms.`;

  const danBlurb = `Dan knows at his core that technology platforms are only as good as the experiences they deliver to 
                    their users and customers.  He brings his 25 years in the web tech and commerce space, managing environments small and large.  
                    Dan loves this project for many reasons, not least that he once again has dirt under his fingernails.`;
  return (
    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#003D7A", pt:2, pb:2, border:"1px solid #6b9cce", }} style={styles.paperContainer}>
    {/* Hero unit */}
      
    
    <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m:4,  p:4 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            }, 
            fontWeight:"bold"
          }}
        >
          Who is the ICPipeline Team?
        </Typography>

        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2}}>
                      
        With a 25+ years’ experience, each, in the digital technology space, we
        have been building businesses, technology platforms and development teams, both as 
        entrepreneurs and transformation leaders in large enterprises, leading and working in unison to 
        deliver great, customer-focused digital products and services.
   
          </Typography>
        <Typography variant="h7" align="left" color="text.primary" component="p" sx={{pt:2, pb:2}}>
                      
        We also have an extensive network of technology, design and marketing profesionals that we can leverage to help
        any project or initiative. We bring a hardened perspective to this newly expoding universe of Web3 and blockchain, mainstream adoption.
        We have been here before and know how to navigate when growth and innovation is the only option for success.
   
          </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>

              <WhoProfile profileName="Matthew Beekman" profileBlurb={mattBlurb} linkedLink="LinkedInBeekman" profileEmail="matthew@icpipeline.com" mainNavClick={props.mainNavClick} />
            
          </Grid>
          <Grid item xs={12} sm={6}>

              <WhoProfile profileName="Daniel Ryan" profileBlurb={danBlurb} linkedLink="LinkedInRyan" profileEmail="daniel@icpipeline.com" mainNavClick={props.mainNavClick} />
            
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", p:2 }}>
               
                      <Typography variant="h7" align="center" color="text.secondary" component="p" sx={{pt:2, pb:2}}>
                      ... it's us plus a whole host of consultants, designers, marketers, program managers and technologists that can help ... 
                      contact us using any of the options in the 
                      
                      <Link
                                  
                                  underline="hover"
                                  align="center"
                                  sx={{p:1, cursor:"pointer"}}
                                  onClick={() => { props.mainNavClick("AboutHelp")}}
                                >
                                  "Need Help"
                      </Link>
                       section and let's get started ... we know, like you do, that the future is now ...
                      </Typography>
            </Box>
          </Grid>
        </Grid>

      </Box>

      <PageNavUpDown theWhereTo="AboutConsulting" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
} // end AboutWho

const WhoProfile = (props) => {

  return (
    <Box sx={{border:"1px solid #9f9f9f" , borderRadius:2, p:2}} >
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{borderBottom:"1px solid #9f9f9f", borderRadius:0, p:1, mb:2, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
          <Typography variant="h6" align="center" color="text.secondary" >
          {props.profileName}
          </Typography>
          <Typography variant="subtitle2" align="center" color="text.secondary" >
          {props.profileEmail}
          </Typography>
          
          </Box>
          

        </Grid>
        <Grid item xs={3}>
          <Box sx={{border:"1px solid #9f9f9f", borderRadius:2, p:1, m:1, display:"flex",  justifyContent: "center" }} >

          <img
                      src={
                      "oneguy.svg"
                      }
                      srcSet={"oneguy.svg?"}
                      alt="Discord"
                      loading="lazy"
                      style={{width:"100px"}}
                    /> 
          </Box>

          

        </Grid>
        <Grid item xs={9} sx={{p:2}} >
          <Typography variant="body" align="center" color="text.secondary" >
          
                      {props.profileBlurb}
          </Typography>
          

        </Grid>
        <Grid item xs={12}>
          <Box sx={{borderTop:"1px solid #9f9f9f", borderRadius:0, p:1, m:1, display:"flex", flexDirection:"column",  justifyContent: "center"}} >
          
                <Tooltip title="LinkedIn" enterNextDelay={300}>
                    <Link
                      variant="button"
                      underline="hover"
                      align="center"
                      sx={{cursor:"pointer"}}
                      onClick={() => { props.mainNavClick(props.linkedLink)}}
                    >
                      <img
                      src={
                      "linkedinlogo.png"
                      }
                      srcSet={"linkedinlogo.png?"}
                      alt="LinkedIn"
                      loading="lazy"
                      style={{width:"50px"}}
                    /> 
                    </Link>
                </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
    );


}

export default AboutWho;