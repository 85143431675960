
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RunCircleIcon from '@mui/icons-material/RunCircle';

const GetStarted = (props) => {


  var theBackground = props.theBackground ? props.theBackground : "#ffffff" ;
  var theText = props.theText ? props.theText : "#000000" ;
  var theBorder = props.theBorder ? props.theBorder : "#9f9f9f" ;

    return (
      <Tooltip title="Visit ICPipeline's main GitHub profile" enterNextDelay={300}>
      <Box onClick={() => { props.mainNavClick("Pilot")}}  sx={{ backgroundColor:theBackground, border:"1px solid ", 
          borderColor:theBorder,
          cursor:'pointer', 
          borderRadius:2,  mb:1,
          p:1,pl:2, display:"flex", flexGrow:1}} >
          
      <Grid container>
      <Grid item xs={2} >

        <Box sx={{pt:1, pb:1, borderRadius:2, backgroundColor:theBackground, border: "2px solid ", borderColor:theBorder, color:theText, width:"50px", flexGrow:1, display:"flex", justifyContent:"center"}} >
        <RunCircleIcon  />
        </Box>
        </Grid>
        <Grid item xs={10} sx={{pt:1.5}}>
        <Typography variant="h7" color={theText} sx={{pl:2, fontSize: {xs:".8em", sm:'1em'}}} >
        Get Started
        </Typography>
  
        </Grid>
        </Grid>
        </Box>
        </Tooltip>
    );
  }// end GetStarted

  export default GetStarted ;
  