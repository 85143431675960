import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS
import GetStarted from '../nav/GetStarted'



const AboutTop = (props) => {



  console.log ("AboutTop RENDERED______")

  const launchDfinity = () => {
    window.open ("https://dfinity.org/", "_blank" );
  };



  return (
    <Paper elevation={0} sx={{border:"0px solid #6b9cce", pb:40, backgroundColor: "#003D7A",border:"1px solid #6b9cce", borderRadius:2, justifyContent:"right",
          backgroundImage: {
            xs: ``,
            sm: `url(${"pipe_bg.png"})`,
          } ,
          backgroundRepeat: `no-repeat`,
          backgroundPositionX:"30%",
          backgroundPositionY:"-2050px",
          height: `520px`
          }} >
      <Grid container>
      <Grid item xs={12} sx={{ display:"flex"}}> 
      
          <Paper elevation={0} sx={{ backgroundColor: "#003D7A", border:"1px solid #6b9cce", borderRadius:2,m:2, p:2,flexGrow:1 }} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                color="#ffffff"
              >
                About Us
              </Typography>

              </Grid>
            </Grid>
          </Paper>
        
        </Grid>
        <Grid item xs={12} >
        <Grid container cellSpacing={2} sx={{pl:2, pr:2}} >
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >

          <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #6b9cce", borderRadius:2, mt:2, mb:2,  p:2, display:"flex", float:"right"}} >
            <Grid container cellSpacing={2}>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("AboutHelp")}} fullWidth variant="contained" sx ={{mb:1}}>
                  Help
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("AboutWho")}} fullWidth variant="contained" sx ={{mb:1}}>
                  Who is ICPipeline?
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("AboutConsulting")}} fullWidth variant="contained" sx ={{mb:1}}>
                  Consulting
                </Button>

              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => { props.mainNavClick("Documentation")}} fullWidth variant="contained" sx ={{mb:1}}>
                  Documentation
                </Button>

              </Grid>
            </Grid>
          </Paper>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sx={{}}>
        <Grid container cellSpacing={2} sx={{pr:2, pl:2, pb:1,pt:1}}>
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
          <GetStarted mainNavClick={props.mainNavClick} theBackground="#003D7A" theBorder="#6b9cce" theText="#fff"/> 
            </Grid>
            </Grid>
        </Grid>
      
        <Grid item xs={12}>
        <Grid container cellSpacing={2} sx={{pl:2, pr:2,pt:1, pb:1}}>
          <Grid item xs={0} sm={6} lg={8}>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} >
          <Tooltip title="Visit Dfinity's website and learn more about the origins of the Internet Computer ... that's where we started" enterNextDelay={300}>
          <Box onClick={() => { props.mainNavClick("Dfinity")}}  sx={{ backgroundColor:"#003D7A", border:"1px solid #6b9cce", 
              cursor:'pointer', 
              borderRadius:2,  mb:2, 
              p:1,pt:1, display:"flex", flexGrow:1}} >
              
          <Grid container>
          <Grid item xs={2} sx={{pt:1}} >

            <img
            src={
            "dinfinity.png"
            }
            srcSet={"dinfinity.png?"}
            alt="Dfinity Logo"
            loading="lazy"
            style={{width:"100%", display:"block"}}
            />
            </Grid>
            <Grid item xs={10} >
            <Typography variant="subtitle2" color={"#bccedd"} sx={{pl:2, fontStyle:"italic"}} >
            Development grant recipients from the Dfinity Foundation.
            </Typography>

            </Grid>
            </Grid>
            </Box>
            </Tooltip>
          </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Paper>

  );
} // end AboutTop

export default AboutTop;