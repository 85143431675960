import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

export default function FullScreenBackdrop(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  var theImgWidth = (props.theImgWidth ? props.theImgWidth : "100%");
  
  return (
    <>
      <Box onClick={handleToggle} sx={{cursor:"pointer"}}>
        <img
            src={
              props.theImgSrc
            }
            srcSet={props.theImgSrc}
            alt={props.theAltText}
            loading="lazy"
            style={{  width: theImgWidth}} 
          />
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >

      <Box  component="main" sx={{ cursor:"pointer", border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m:4,  p:2 }}>
        
          <Box
              component="img"
              src={props.theImgSrc}
              alt="Logo"
              sx={{p:1, width:'100%'}}
          />
      </Box>
      </Backdrop>
    </>
  );
}// FullScreenBackdrop