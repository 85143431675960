import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';


/// ****** CUSTOM IMPORTS 

function preventDefault(event) {
  event.preventDefault();
}

const GitbookSpace = (props) => {


  var spaceId = props.spaceId ;
  var spaceUrl = props.spaceUrl ;
  var displayLocation = props.displayLocation;

  const windowHeight = window.innerHeight ;
  const windowWidth = window.innerWidth ;
  var frameHeight =  windowHeight - 490;
  if (frameHeight < 500 ) 
    frameHeight=500;


  
  console.log("windowHeight: ",windowHeight);
  console.log("windowWidth: ",windowWidth);


  if (!spaceUrl) {
    spaceUrl = "https://icpipeline.gitbook.io/icpipeline-testing/"
  }
  //spaceUrl = spaceUrl +"?var=" + (Math.floor(Math.random() * 300000)).toString();
  
  function resizeIFrameToFitContent( iFrame ) {

    iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
    iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
  }

  const tempId = `#${spaceId}`;

  useEffect(() => {


        const GitbookFrame = (document).getElementById(`${spaceId}`);


        console.log ("spaceId: ", spaceId) ;

        console.log ("GitbookFrame: ", GitbookFrame) ;
      
        if (GitbookFrame) {
      
          GitbookFrame.onload = function() {
            console.log ("WOOOOOOOOOOTTT");
          
            // console.log ("height: ",GitbookFrame.contentWindow.document.body.scrollHeight);
            // resizeIFrameToFitContent(GitbookFrame);
          }
        }
  


  }, []);


  //var tempWindowHeight = 1000;
  var tempWindowHeight =450;

  if (displayLocation== "helpPop" ) {
    tempWindowHeight = document.body.scrollHeight-500;
  }

    console.log ("document.body.scrollHeight: ", document.body.scrollHeight) ;





  return (
    <>
    <Box sx={{border: '0px solid #9f9f9f',
              borderRadius:2,
              p:0,
              m:0 }} >

      
      <CardMedia id={spaceId} component="iframe" src={spaceUrl} frameBorder="0" sx={{minHeight:`${frameHeight}px` , overflow:"visible", borderBottom:"0px solid #cccccc"}}>
        Loading
      </CardMedia>



    </Box>
    
      
    </>
  );
}// end GitbookSpace


export default GitbookSpace;
