import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import thisFile from "../readmes/ReadmeComplete.js"

// **** CUSTOM IMPORTS
import GetStarted from '../nav/GetStarted'
import ShowFrameworkReadme from '../nav/ShowFrameworkReadme'

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';
const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges-bw.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};



const DocumentationComplete=  (props) => {



  console.log ("DocumentationComplete RENDERED______")
  
  const [showReadme, setShowReadme] = useState(false);
  

  const launchDfinity = () => {
    
    window.open ("https://dfinity.org/", "_blank" );
  };
  const showReadmeClick = () => {
    setShowReadme (!showReadme);
    console.log ("clicked to display readme");
  };



  var displayReadme =[
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={5} lg={4} sx={{display:"flex", flexDirection:"column "}} >

          <ShowFrameworkReadme showReadmeClick={showReadmeClick} />

          </Grid>
          <Grid item xs={0} md={2} lg={4} >
            
          </Grid>
          <Grid item xs={12} md={5} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick} />
          </Grid>
        </Grid>
        
      </Box>


  ];
  if (showReadme) {
    displayReadme = [
      <Box key={1} >
        <Grid container>
          <Grid item xs={12} md={8} lg={8} >
                    
                
            <Typography
              component="h4"
              variant="h4"
              align="left"
              color="#000000"
              sx={{fontWeight:"bold", pt:2}}
            >
              Framework: ReadMe
            </Typography>
        
            <Link
                color="text.primary"
                underline="hover"
                sx={{ my: 1, mx: 1.5 }}
                onClick={() => { showReadmeClick()}}
              >
                (hide)
              </Link>
          </Grid>
          <Grid item xs={12} md={4} lg={4} >
              <GetStarted mainNavClick={props.mainNavClick}/> 
          </Grid>
        </Grid>
        
        <ReactMarkdown remarkPlugins={[gfm]} >{thisFile}</ReactMarkdown>
      </Box>
    ];
  }

  //console.log ("thisFile: ",thisFile)

  
  const launchGitHub = () => {
    window.open ("https://github.com/icpipeline-framework", "_blank" );
  };
  

  return (
    <Paper elevation={0} sx={{ backgroundColor:"#ffffff", justifyContent:"right", borderRadius:2, p:2} }  style={styles.paperContainer}>
      <PageNavUpDown theWhereTo="Documentation" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

    <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff", m:2,  p:0 }}>
  

      <Grid container>              
      
      <Grid item xs={12} sx={{pt:2}}>
        <Typography
          component="h5"
          variant="h5"
          align="center"
          color="#000000"
        >
          ICPipeline Framework Installer
        </Typography>
            <Typography variant="subtitle2" align="center"  color="text.secondary" sx={{ fontStyle:"italic", pr:2, pl:2, pt:2}} >
            Our installer will stand up your complete ICPipeline framework, end-to-end.  
            
            You just clone it and run it, 
            and you'll be ready to start deploying your own IC projects, on *your* dedicated ICPipeline. 
            
            Also included is a "Resetter" module, which will completely roll back any installation, down to the last detail.  
            
            It's designed for you to iterate, get a few reps, break things fearlessly.  So by all means please do so.  
            
            And we are nice, friendly folk here, who'll be happy to help if you need a hand.  
            
            We appreciate your interest, and we look forward to hearing your impressions, feedback and ideas for the platform going forward.
            </Typography>

      </Grid>
        
        <Grid item xs={12} >

        <Box elevation={0} sx={{ backgroundColor: "#ffffff",  borderRadius:2,p:0, m:"auto", mt:0, mb:0, width:"100%",  justifyContent:"center"}} >
        <FullScreenImg  theImgSrc="icpipeline-complete-framework-overview.png" theAltText="ICPipeline Complete Framework - This sofware will walk you through the creation and deployment of your pipeline. "/>
       
  
        </Box>

        </Grid>
      </Grid>
    </Box>
    <Grid container>
      <Grid item xs={12} >
      <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"1px solid #9f9f9f",
          borderRadius:2,m:2, p:2, flexGrow:1, overflowX:"auto" }} >
      
      {displayReadme}
      </Paper>

      </Grid>
      </Grid>
    
      <PageNavUpDown theWhereTo="DocumentationICPM" theUpDown="down" theUpDownText="next" mainNavClick={props.mainNavClick}/>
    </Paper>


  );
} // end DocumentationComplete

export default DocumentationComplete;