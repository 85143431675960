import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

// dr adding mui tooling for lists
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// dr import mui link tooling
import Link from '@mui/material/Link';

import StarIcon from '@mui/icons-material/StarBorder';

// **** CUSTOM IMPORTS

import FullScreenImg from '../nav/FullScreenImg';
import PageNavUpDown from '../nav/PageNavUpDown';

const styles = {
  paperContainer: {
      backgroundImage: `url(${"pipe-edges.png"})` ,
      backgroundRepeat: "repeat-y",
      backgroundPosition: `30%`,
  }
};

const FrameworkWhat = (props) => {

  console.log ("FrameworkWhat RENDERED______")

  return (

    <Paper  elevation={0} sx={{ borderRadius:2, backgroundColor: "#003D7A", pt:2, pb:2, border:"1px solid #6b9cce", }} style={styles.paperContainer}>
      {/* Hero unit */}
      
      <PageNavUpDown theWhereTo="Framework" theUpDown="up" theUpDownText="back to top" mainNavClick={props.mainNavClick}/>

      <Box  component="main" sx={{ border: "1px solid #9f9f9f", borderRadius:2, backgroundColor: "#ffffff",  m: { xs:2, sm:4} ,  p: { xs:2, sm:4} }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.secondary"
          gutterBottom
          sx={{
            fontSize: {
              xs: "2em",
              sm: "4em",
            },
            fontWeight:"bold"
          }}
        >
          What is ICPipeline?
        </Typography>

        <Typography variant="h5" align="center" color="text.primary" component="p" sx={{pt:0, pb:2, fontStyle:"italic"}}>
          An intuitive framework delivering simpler, better workflows for all builders on the Internet Computer.
        </Typography>
      
        <Paper elevation={0} sx={{ backgroundColor: "#ffffff", border:"0px solid #9f9f9f", borderRadius:2, m:"auto", mt:2, mb:2, width:"85%", p:2,  justifyContent:"center"}} >      
          <FullScreenImg theImgSrc="icpipeline-development-stages.png" theAltText="An E2E project management framework built expressly for the Internet Computer."/>
        </Paper>
        <Typography variant="body2" align="left" color="text.primary" component="p" sx={{pb:2, fontStyle: "bold", fontSize: "1.4em"}}>
          If you're here, you're probably encountering the same challenges most Developers and teams on the IC are currenly trying to overcome. 

        </Typography>
        <Typography variant="body2" align="left" color="text.primary" component="p" sx={{pb:2, fontStyle: "bold", fontSize: "1.4em"}}>
          That's exactly what our tools are built for ... ICPipeline can help.
          
        </Typography>
            <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold", ml:1}}>
                      ICPipeline delivers a better IC developer experience
                  </Typography> 
                </Grid>
              <Grid item xs={12} md={6}>
              
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:3, }}>
                    <FullScreenImg theImgSrc="icdevops.png" theAltText="DevOps"/>
                    </Box>
              </Grid>
                <Grid item xs={12} md={6}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                    <ListItem>
                      Our core mission is delivering a better developer experience in the IC ecosystem.
                    </ListItem>
                    <ListItem>
                      Simpler, faster, better IC dev workflows, throughout the dev lifecycle.
                    </ListItem>
                    <ListItem>
                      Our no-cost setup takes just minutes, and delivers practical useability and real-world value at any scale.
                    </ListItem>
                    <ListItem>
                      ICPipeline is your self-contained, <em>n</em>-tiered project platform, designed and built specifically for the IC.
                    </ListItem>
                    <ListItem>
                      Streamlined and simplified, but still comprehensive.
                    </ListItem>
                  </List>
              </Grid>
            </Grid>
        

        <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold", ml:1}}>
                  Design your project pipeline with ICPipeline Manager aka ICPM

                  </Typography> 
                </Grid>
                <Grid item xs={12} md={6}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                    <ListItem>
                    ICPM runs all-on-chain -- it's on the Internet Computer, for the Internet Computer.
                    </ListItem>
                    <ListItem>
                      The framework leverages on-demand replica environments, starting with your own workstation.
                    </ListItem>
                    <ListItem>
                      Then transition seamlessly from your local build to dedicated Remote Host Replicators.
                    </ListItem>
                    <ListItem>
                      Each one is a dedicated virtual host, fully networked, with built-in DNS and CDN support -- and they're on-demand with just a few clicks.
                    </ListItem>
                    <ListItem>
                      So you can deploy to QA, and ship the link to your team -- say goodbye to shoulder surfing and screen sharing.
                    </ListItem>
                    <ListItem>
                      You can build, iterate and break things for as long as it takes, in your own access-controlled sandbox.
                    </ListItem>
                    <ListItem>
                      And when you're ready, simply deploy to canisters on the live blockchain.
                    </ListItem>
                    <ListItem>
                      If you prefer to have an on-chain, pre-production Stage tier, that's easy too.
                    </ListItem>
                  </List>

              </Grid>
              <Grid item xs={12} md={6}>
              
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:3, }}>
                    <FullScreenImg theImgSrc="iccontinuousintegration.png" theAltText="Continuous Integration"/>
                    </Box>
              </Grid>
            </Grid>
            

        <Grid container sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:1}} >
                <Grid item xs={1} sx={{ display:"flex", justifyContent:"center", pt:1.3}}>
                    <Box sx={{width:"40px"}} >  
                      <img
                      src={
                      "dinfinity.png"
                      }
                      srcSet={"dinfinity.png?"}
                      alt="Dfinity Logo"
                      loading="lazy"
                      style={{width:"100%", display:"block"}}
                      />
                    </Box>
                </Grid>
                <Grid item xs={11}>
                <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{fontWeight:"bold", ml:1}}>
                  ICPipeline will handle as many projects, environments, repos and branches as you need.

                  </Typography> 
                </Grid>
              <Grid item xs={12} md={6}>
              
                    <Box sx={{ border:"1px solid #cccccc", borderRadius:2, p:2, m:3, }}>
                    <FullScreenImg theImgSrc="iccontinuousdeployment.png" theAltText="Continuous Deployment"/>
                    </Box>
              </Grid>
                <Grid item xs={12} md={6} sx={{}}>
                  <List
                    sx = {{
                      listStyleType: 'disc',
                      pl: 2,
                      '& .MuiListItem-root': {
                        display: 'list-item',
                      },
                    }
                  }>
                    <ListItem>
                      You're in full control of everything, and it's all as public or private as you want it.
                    </ListItem>
                    <ListItem>
                      ICPipeline makes it easy to capture and save your build steps, at any degree of complexity.
                    </ListItem>
                    <ListItem>
                      Just enter it once and save, then just click Deploy to execute -- the same way every time, error-free.
                    </ListItem>
                    <ListItem>
                      ICPipeline requires no changes to your code.
                    </ListItem>
                    <ListItem>
                      No proprietary Kool-Aid here, just straight-up IC building, hands-on and end-to-end.
                    </ListItem>
                    <ListItem>
                      To migrate projects already in-flight, just enter a few keystrokes into ICPM.
                    </ListItem>
                    <ListItem>
                      Basically point it at your project repo(s) and you're all set, ready to start building and deploying.
                    </ListItem>
                  </List>

              </Grid>
            </Grid>

      
      </Box>

      <PageNavUpDown theWhereTo="FrameworkWhatItIsnt" theUpDown="down" theUpDownText="next section" mainNavClick={props.mainNavClick}/>

    </Paper>

  );
} // end FrameworkWhat

export default FrameworkWhat;